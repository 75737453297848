<template>
   <b-container fluid="">
      <b-row>
         <b-col sm="12">
               <card>
                  <template v-slot:headerTitle>
                        <h4 class="card-title">Dripicons Icon</h4>
                  </template>
                  <template v-slot:body>
                     <p>Use Class <code>&lt;div data-icon="a" class="icon"&gt;&lt;/div&gt;</code></p>
                     <div class="row mt-3">
                        <div class="col-sm-6 col-md-4 col-lg-3">
                           <a class="iq-icons-list" href="#" >
                              <i class="dripicons dripicons-alarm"></i>
                              <span>4k Fill</span>
                           </a>
                        </div>
                        <div class="col-sm-6 col-md-4 col-lg-3">
                           <a class="iq-icons-list" href="#" >
                              <i class="dripicons dripicons-arrow-up"></i>
                              <span>Up Arrow</span>
                           </a>
                        </div>
                        <div class="col-sm-6 col-md-4 col-lg-3">
                           <a class="iq-icons-list" href="#" >
                              <i class="dripicons dripicons-arrow-down"></i>
                              <span>Down Arrow</span>
                           </a>
                        </div>
                        <div class="col-sm-6 col-md-4 col-lg-3">
                           <a class="iq-icons-list" href="#" >
                              <i class="dripicons dripicons-arrow-left"></i>
                              <span>Left Arrow</span>
                           </a>
                        </div>
                        <div class="col-sm-6 col-md-4 col-lg-3">
                           <a class="iq-icons-list" href="#" >
                              <i class="dripicons dripicons-arrow-right"></i>
                              <span>Right Arrow</span>
                           </a>
                        </div>
                        <div class="col-sm-6 col-md-4 col-lg-3">
                           <a class="iq-icons-list" href="#" >
                              <i class="dripicons dripicons-arrow-thin-left"></i>
                              <span>left</span>
                           </a>
                        </div>
                        <div class="col-sm-6 col-md-4 col-lg-3">
                           <a class="iq-icons-list" href="#" >
                              <i class="dripicons dripicons-arrow-thin-down"></i>
                              <span>Down</span>
                           </a>
                        </div>
                        <div class="col-sm-6 col-md-4 col-lg-3">
                           <a class="iq-icons-list" href="#" >
                              <i class="dripicons dripicons-arrow-up"></i>
                              <span>Up</span>
                           </a>
                        </div>
                        <div class="col-sm-6 col-md-4 col-lg-3">
                           <a class="iq-icons-list" href="#" >
                              <i class="dripicons dripicons-arrow-thin-up"></i>
                              <span>Right</span>
                           </a>
                        </div>
                        <div class="col-sm-6 col-md-4 col-lg-3">
                           <a class="iq-icons-list" href="#" >
                              <i class="dripicons dripicons-anchor"></i>
                              <span>Anchor</span>
                           </a>
                        </div>
                        <div class="col-sm-6 col-md-4 col-lg-3">
                           <a class="iq-icons-list" href="#" >
                              <i class="dripicons dripicons-article"></i>
                              <span>File</span>
                           </a>
                        </div>
                        <div class="col-sm-6 col-md-4 col-lg-3">
                           <a class="iq-icons-list" href="#" >
                              <i class="dripicons dripicons-card"></i>
                              <span>Card</span>
                           </a>
                        </div>
                        <div class="col-sm-6 col-md-4 col-lg-3">
                           <a class="iq-icons-list" href="#" >
                              <i class="dripicons dripicons-direction"></i>
                              <span>Direction</span>
                           </a>
                        </div>
                        <div class="col-sm-6 col-md-4 col-lg-3">
                           <a class="iq-icons-list" href="#" >
                              <i class="dripicons dripicons-download"></i>
                              <span>Download</span>
                           </a>
                        </div>
                        <div class="col-sm-6 col-md-4 col-lg-3">
                           <a class="iq-icons-list" href="#" >
                              <i class="dripicons dripicons-loading"></i>
                              <span>Sun</span>
                           </a>
                        </div>
                        <div class="col-sm-6 col-md-4 col-lg-3">
                           <a class="iq-icons-list" href="#" >
                              <i class="dripicons dripicons-graph-line"></i>
                              <span>Graph line</span>
                           </a>
                        </div>
                        <div class="col-sm-6 col-md-4 col-lg-3">
                           <a class="iq-icons-list" href="#" >
                              <i class="dripicons dripicons-lock"></i>
                              <span>Lock</span>
                           </a>
                        </div>
                        <div class="col-sm-6 col-md-4 col-lg-3">
                           <a class="iq-icons-list" href="#" >
                           <i class="dripicons dripicons-power"></i>
                              <span>Power</span>
                           </a>
                        </div>
                        <div class="col-sm-6 col-md-4 col-lg-3">
                           <a class="iq-icons-list" href="#" >
                              <i class="dripicons dripicons-pill"></i>
                              <span>Pill</span>
                           </a>
                        </div>
                        <div class="col-sm-6 col-md-4 col-lg-3">
                           <a class="iq-icons-list" href="#" >
                              <i class="dripicons dripicons-pencil"></i>
                              <span>Pencil</span>
                           </a>
                        </div>
                        <div class="col-sm-6 col-md-4 col-lg-3">
                           <a class="iq-icons-list" href="#" >
                              <i class="dripicons dripicons-alarm"></i>
                              <span>Battery Empty</span>
                           </a>
                        </div>
                        <div class="col-sm-6 col-md-4 col-lg-3">
                           <a class="iq-icons-list" href="#" >
                              <i class="dripicons dripicons-bell"></i>
                              <span>Bell</span>
                           </a>
                        </div>
                        <div class="col-sm-6 col-md-4 col-lg-3">
                           <a class="iq-icons-list" href="#" >
                              <i class="dripicons dripicons-bookmark"></i>
                              <span>Bookmark</span>
                           </a>
                        </div>
                        <div class="col-sm-6 col-md-4 col-lg-3">
                           <a class="iq-icons-list" href="#" >
                              <i class="dripicons dripicons-box"></i>
                              <span>Box</span>
                           </a>
                        </div>
                        <div class="col-sm-6 col-md-4 col-lg-3">
                           <a class="iq-icons-list" href="#" >
                              <i class="dripicons dripicons-cloud"></i>
                              <span>Cloud</span>
                           </a>
                        </div>
                        <div class="col-sm-6 col-md-4 col-lg-3">
                           <a class="iq-icons-list" href="#" >
                              <i class="dripicons dripicons-cross"></i>
                              <span>Cross</span>
                           </a>
                        </div>
                        <div class="col-sm-6 col-md-4 col-lg-3">
                           <a class="iq-icons-list" href="#" >
                              <i class="dripicons dripicons-message"></i>
                              <span>Message</span>
                           </a>
                        </div>
                        <div class="col-sm-6 col-md-4 col-lg-3">
                           <a class="iq-icons-list" href="#" >
                              <i class="dripicons dripicons-stack"></i>
                              <span>Stack</span>
                           </a>
                        </div>
                        <div class="col-sm-6 col-md-4 col-lg-3">
                           <a class="iq-icons-list" href="#" >
                              <i class="dripicons dripicons-swap"></i>
                              <span>Swap</span>
                           </a>
                        </div>
                        <div class="col-sm-6 col-md-4 col-lg-3">
                           <a class="iq-icons-list" href="#" >
                              <i class="dripicons dripicons-user-id"></i>
                              <span>User id</span>
                           </a>
                        </div>
                        <div class="col-sm-6 col-md-4 col-lg-3">
                           <a class="iq-icons-list" href="#" >
                              <i class="dripicons dripicons-wifi"></i>
                              <span>Wifi</span>
                           </a>
                        </div>
                        <div class="col-sm-6 col-md-4 col-lg-3">
                           <a class="iq-icons-list" href="#" >
                              <i class="dripicons dripicons-wrong"></i>
                              <span>Wrong</span>
                           </a>
                        </div>
                        <div class="col-sm-6 col-md-4 col-lg-3">
                           <a class="iq-icons-list" href="#" >
                              <i class="dripicons dripicons-alarm"></i>
                              <span>Angle Up</span>
                           </a>
                        </div>
                        <div class="col-sm-6 col-md-4 col-lg-3">
                           <a class="iq-icons-list" href="#" >
                              <i class="dripicons dripicons-zoom-in"></i>
                              <span>Zoom in</span>
                           </a>
                        </div>
                        <div class="col-sm-6 col-md-4 col-lg-3">
                           <a class="iq-icons-list" href="#" >
                              <i class="dripicons dripicons-zoom-out"></i>
                              <span>Zoom out</span>
                           </a>
                        </div>
                        <div class="col-sm-6 col-md-4 col-lg-3">
                           <a class="iq-icons-list" href="#" >
                              <i class="dripicons dripicons-trash"></i>
                              <span>Trash</span>
                           </a>
                        </div>
                        <div class="col-sm-6 col-md-4 col-lg-3">
                           <a class="iq-icons-list" href="#" >
                              <i class="dripicons dripicons-ticket"></i>
                              <span>Ticket</span>
                           </a>
                        </div>
                        <div class="col-sm-6 col-md-4 col-lg-3">
                           <a class="iq-icons-list" href="#" >
                              <i class="dripicons dripicons-upload"></i>
                              <span>Upload</span>
                           </a>
                        </div>
                        <div class="col-sm-6 col-md-4 col-lg-3">
                           <a class="iq-icons-list" href="#" >
                              <i class="dripicons dripicons-view-apps"></i>
                              <span>View app</span>
                           </a>
                        </div>
                        <div class="col-sm-6 col-md-4 col-lg-3">
                           <a class="iq-icons-list" href="#" >
                              <i class="dripicons dripicons-toggles"></i>
                              <span>Toggles</span>
                           </a>
                        </div>
                        <div class="col-sm-6 col-md-4 col-lg-3">
                           <a class="iq-icons-list" href="#" >
                              <i class="dripicons dripicons-to-do"></i>
                              <span>Todo</span>
                           </a>
                        </div>
                        <div class="col-sm-6 col-md-4 col-lg-3">
                           <a class="iq-icons-list" href="#" >
                              <i class="dripicons dripicons-tags"></i>
                              <span>Tags</span>
                           </a>
                        </div>
                        <div class="col-sm-12 text-center mt-3">
                           <a class="btn btn-primary" href="http://demo.amitjakhu.com/dripicons/" target="blank">View All Icon</a>
                        </div>
                     </div>
                  </template>
               </card>
         </b-col>
      </b-row>
   </b-container>
</template>
<script>
export default {
    name:'Dripicons'
}
</script>